import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const WaneloPage = () => (
  <Layout>
        <div class="nav_bar">
          <Link to="">About</Link>
          <Link to="/wanelo/" activeClassName="active">Work</Link>
        </div>
        <div class="work_nav_bar">
        <Link to="/quora/" class="work_links">Quora</Link>
        <Link to="/facebook/" class="work_links">Facebook</Link>
        <Link to="/wanelo/" class="work_links" activeClassName="active">Wanelo</Link> 
      </div>
      <h1>Wanelo</h1>
      <ul class="list">
            <li>2011 - 2014</li>
        </ul>
        <p>Wanelo is a shopping discovery app that has products from almost every store on the internet 
        and lets you post and save things you love. I joined in 2011 in a generalist role; my first responsibilities 
        included working on fundraising for our seed round, managing all of our customer support communications, 
        keeping the site up by restarting our servers, and running user acquisition ad campaigns. 
        After we raised a seed round and started building out our team, I was able to focus on product design 
        and designed Wanelo's iPhone app, which quickly reached the top 30 free apps in the App Store and 
        grew the user base significantly. During my time at Wanelo I also worked on designing profiles, shopping stories, 
        the activity feed, and more. </p>

        <h2>Profiles</h2>
        <ul class="list">
            <li>Role: Product Designer</li>
        </ul>
        <p></p>

        <h2>Feeds</h2>
        <ul class="list">
            <li>Role: Product Designer</li>
        </ul>
        <p></p>

        <h2>Stories</h2>
        <ul class="list">
            <li>Role: Product Designer</li>
            <li>2013</li>
        </ul>
        <p>Before we built Stories, the main activities that users did on Wanelo were browsing the Trending feed 
        on the homepage which showed them the most saved products from the last few days and save those products themselves, 
        and posting products to Wanelo that they found elsewhere on the internet. We wanted to let users express their style 
        and aesthetic to their audience on Wanelo, and we built Stories as a way to let users arrange a bundle of products 
        they liked and tell a short story about that bundle. Below are some examples of Stories I posted back in the day. </p>
  </Layout>
)

export default WaneloPage